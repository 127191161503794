@use 'sass:math';

html, body {
  min-height: 100%;
  height: 100%;
  font: 16px 'Source Sans 3', sans-serif;
  line-height: 1.4;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div {
  box-sizing: border-box;
}

.fury-scrollblock {
  position: fixed;
  width: 100%;
}

.fury-collapsable-sidenav {
  overflow: visible !important;
  border-right: none !important;

  .mat-drawer-inner-container {
    overflow: visible !important;
  }
}

.fury-flex-form-field {
  .mat-form-field-infix {
    width: 0;
  }
}

.mat-menu-panel.fury-inbox-mail-label-list-menu {
  max-height: 350px;
  padding-top: math.div($spacing, 3);

  .fury-inbox-mail-label-list-menu-subheading {
    font: var(--font-caption);
    color: yellow; // TODO: $theme-secondary-text
    font-weight: 500;
    padding-left: math.div($spacing, 2);
    padding-right: math.div($spacing, 2);
    margin-bottom: math.div($spacing, 3);
  }

  .mat-checkbox {
    margin-right: math.div($spacing, 2);
  }

  .fury-inbox-mail-label-list-create {
    padding-left: math.div($spacing, 2);
    padding-right: math.div($spacing, 2);
  }

  .fury-inbox-mail-label-list-color, .fury-inbox-mail-label-list-name {

    .mat-form-field-infix {
      width: auto;
    }
  }
}

.fury-inbox-mail-label-list-color-select {
  .fury-inbox-mail-label-list-color-select-indicator {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-block;
    margin-right: math.div($spacing, 2);
    vertical-align: middle;
  }
}

h3 {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.005em;
}

pre {
  margin: 0;
}

code {
  white-space: pre;
}

p {
  margin: 0 0 17px 0;
}

.leaflet-div-icon {
  background: transparent !important;
  border: none !important;
}

.leaflet-custom-marker-pin {
  color: #333;
  position: absolute !important;
  border-radius: 50%;
  border: 8px solid currentColor;
  width: 24px;
  height: 24px;

  &::before {
    content: '';
    box-shadow: 0 20px 10px 0 #000;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: -15px;
    width: 26px;
    height: 4px;
    rotate: -43deg;
    z-index: -1;
  }

  &::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    bottom: -30px;
    left: -6px;
    border: 10px solid transparent;
    border-top: 17px solid currentColor;
  }

  &.company-marker::after {
    display: none;
  }
}
