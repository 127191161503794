.fury-page-layout {
  display: block;
}

.fury-container {
  display: block;
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;

  &.header {
    max-width: 1270px;
  }
}

.fury-page-layout-header {
  display: block;
  padding: 10px 15px;
  background: #fff;
}

.fury-page-layout-content {
  display: block;

  .fury-container {
    background: #fff;
    padding: 55px;
  }

  @media (max-width: 959px) {
    .fury-container {
      padding: 25px;
    }
  }
}
