.content .mat-button,
.content .mat-raised-button {
  display: block;
  text-align: center;
  width: 100%;
  padding: 9px 28px;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: .6px;
  border: 2px solid var(--border-color);
}

.mat-button .mat-icon {
  vertical-align: middle;
  margin-left: 10px;
}

.mat-button.mat-primary,
.mat-raised-button.mat-primary {
  background-color: var(--city-color);
  color: var(--text-color);
  border: 2px solid var(--city-color);
}

.mat-button.mat-secondary,
.mat-raised-button.mat-secondary {
  color: var(--text-color);
  background: #fff;
  border: 2px solid var(--text-color);
}

.mat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-badge-content.mat-accent {
  background-color: #DD2815;
  color: #ffffff;
}

.mat-button.mat-default,
.mat-raised-button.mat-default,
.mat-badge-content.mat-primary {
  background-color: #cccccc;
  color: #333333;
}

.mat-button.mat-warn,
.mat-raised-button.mat-warn,
.mat-badge-content.mat-warn, {
  background-color: #FFA500;
  color: #333333;
}

.mat-button.mat-black,
.mat-raised-button.mat-black,
.mat-badge-content.mat-black, {
  background-color: #333;
  color: #fff;
}

.mat-button.mat-primary.mat-button-disabled,
.mat-raised-button.mat-primary.mat-button-disabled,
.mat-button.mat-secondary.mat-button-disabled,
.mat-raised-button.mat-secondary.mat-button-disabled,
.mat-button.mat-accent.mat-button-disabled,
.mat-raised-button.mat-accent.mat-button-disabled,
.mat-button.mat-default.mat-button-disabled,
.mat-raised-button.mat-default.mat-button-disabled,
.mat-button.mat-warn.mat-button-disabled,
.mat-raised-button.mat-warn.mat-button-disabled {
  opacity: 0.6;
}

.mat-checkbox-indeterminate.mat-dark-grey .mat-checkbox-background,
.mat-checkbox-checked.mat-dark-grey .mat-checkbox-background {
  background: #555555;
}
