@use '@angular/material' as mat;
@import "./mixins";

$config: mat.define-typography-config(
        $font-family: var(--font-family),
        $display-4: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
        $display-3: mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
        $display-2: mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
        $display-1: mat.define-typography-level(34px, 40px, 400),
        $headline: mat.define-typography-level(24px, 32px, 400),
        $title: mat.define-typography-level(18px, 26px, 500),
        $subheading-2: mat.define-typography-level(16px, 28px, 400),
        $subheading-1: mat.define-typography-level(15px, 24px, 400),
        $body-2: mat.define-typography-level(14px, 24px, 500),
        $body-1: mat.define-typography-level(14px, 20px, 400),
        $caption: mat.define-typography-level(12px, 20px, 400),
        $button: mat.define-typography-level(14px, 14px, 500),
        $input: mat.define-typography-level(14px, 1.125, 400)
);

:root {
  --border-color: #666;
  --text-color: #444;
  /*    --city-color: #1b143c;*/
  --city-color: #95c234;
  --error-color: #ee0000;
  --button-background: #ebebeb;
}
